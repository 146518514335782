import React from 'react';
import GlobalStyles from './designSystem/GlobalStyles';
import styled from '@emotion/styled';
import Pixelater from './components/Pixelater';

const App: React.FC = () => {
	return (
		<StyledApp>
			<GlobalStyles />
			<Pixelater />
		</StyledApp>
	);
};

const StyledApp = styled.main`
	width: 100%;
	height: 100%;
`;

export default App;
