import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { getCanvasFromFile } from '../utils/canvasHelpers';
import theme from '../designSystem/theme';
import SvgPicture from '../designSystem/icons/SvgPicture';
import Slider from './Slider';
import Color from 'color';
import debounce from 'lodash/debounce';

const performImageProcessing = debounce(
	(
		sourceCanvas: React.MutableRefObject<HTMLCanvasElement>,
		outputCanvas: React.MutableRefObject<HTMLCanvasElement>,
		rasterNumber: number,
		saturationFactor: number,
		hueFactor: number,
		setImageDataUrl: (url: string) => void
	) => {
		const width = sourceCanvas.current.width;
		const height = sourceCanvas.current.height;
		outputCanvas.current.width = width;
		outputCanvas.current.height = height;
		const context = outputCanvas.current.getContext('2d')!;
		context.drawImage(sourceCanvas.current, 0, 0);

		if (rasterNumber > 0) {
			const rasterSize = width / Math.round((1 - rasterNumber / 100) * 100 + 2);
			const pixelArray = context.getImageData(0, 0, width, height).data;
			for (let y = 0; y < height; y += rasterSize) {
				for (let x = 0; x < width; x += rasterSize) {
					const position = (Math.round(x) + Math.round(y) * width) * 4;
					const color = Color.rgb(pixelArray[position], pixelArray[position + 1], pixelArray[position + 2]);
					let outputColor = color.saturate((saturationFactor - 0.3) * 10);
					outputColor = outputColor.rotate(360 * hueFactor - 180);
					context.fillStyle = outputColor.rgb();
					context.fillRect(x, y, rasterSize, rasterSize);
				}
			}
		}

		setImageDataUrl(outputCanvas.current.toDataURL());
	},
	300,
	{
		maxWait: 300
	}
);

const Pixelater: React.FC = () => {
	const [imageDataUrl, setImageDataUrl] = useState();
	const [rasterNumber, setRasterNumber] = useState(90);
	const [saturationFactor, setSaturationFactor] = useState(0.9);
	const [hueFactor, setHueFactor] = useState(0.5);
	const sourceCanvas = useRef<HTMLCanvasElement>();
	const outputCanvas = useRef<HTMLCanvasElement>(null);

	const onDrop = useCallback(
		async acceptedFiles => {
			const file = acceptedFiles[0];
			if (file) {
				sourceCanvas.current = await getCanvasFromFile(file, 1200);
				setImageDataUrl(sourceCanvas.current.toDataURL());
			} else {
				console.warn('file not accepted (too big or wrong type)');
			}
		},
		[] // eslint-disable-line
	);
	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		maxSize: 15000000, // 15 MB
		accept: 'image/jpeg,image/png,image/gif',
		onDrop
	});

	useEffect(() => {
		if (sourceCanvas.current && outputCanvas.current) {
			performImageProcessing(
				sourceCanvas as React.MutableRefObject<HTMLCanvasElement>,
				outputCanvas as React.MutableRefObject<HTMLCanvasElement>,
				rasterNumber,
				saturationFactor,
				hueFactor,
				setImageDataUrl
			);
		}
	}, [sourceCanvas, outputCanvas, rasterNumber, saturationFactor, hueFactor, imageDataUrl]);

	return (
		<StyledPixelater>
			<div>
				<StyledDropzone {...getRootProps()}>
					<input {...getInputProps()} />
					{imageDataUrl ? <img src={imageDataUrl} alt="" /> : <SvgPicture />}
				</StyledDropzone>
				<canvas id="output" ref={outputCanvas} />
				<Slider className="horizontal-slider" value={rasterNumber} onChange={value => setRasterNumber(value)} />
				<Slider
					className="vertical-slider-left"
					value={saturationFactor * 100}
					orientation="vertical"
					onChange={value => setSaturationFactor(value / 100)}
				/>
				<Slider
					className="vertical-slider-right"
					value={hueFactor * 100}
					orientation="vertical"
					onChange={value => setHueFactor(value / 100)}
				/>
			</div>
		</StyledPixelater>
	);
};

const StyledPixelater = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		width: calc(100% - 150px);
		height: calc(100% - 150px);
		max-width: 1000px;
		max-height: 1000px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> canvas {
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;
			display: none;
		}
	}

	.horizontal-slider {
		width: 80%;
		position: absolute;
		left: 10%;
		bottom: -60px;
	}

	.vertical-slider-left {
		height: 80%;
		position: absolute;
		top: 10%;
		left: -60px;
	}

	.vertical-slider-right {
		height: 80%;
		position: absolute;
		top: 10%;
		right: -60px;
	}
`;

const StyledDropzone = styled('div')`
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	position: relative;
	//background-color: ${theme.colors.imageBackground};
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	svg {
		width: 200px;
		height: 200px;
		opacity: 0.1;
	}
`;

export default Pixelater;
