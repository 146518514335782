import * as React from 'react';
import { Global, css } from '@emotion/core';
import { normalize } from 'polished';
import theme from './theme';

export default () => (
	<React.Fragment>
		<Global
			styles={css`
				${normalize()}
			`}
		/>
		<Global
			styles={css`
				html,
				body {
					height: 100%;
					min-height: 500px;
					min-width: 320px;
					background-color: ${theme.colors.white};
					font-size: ${theme.typography.body.fontSize};
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-family: ${theme.typography.body.fontFamily.join(',')};
					font-weight: ${theme.typography.body.fontWeight};
					line-height: ${theme.typography.body.lineHeight};
					color: ${theme.colors.text};
					-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
					touch-action: manipulation;
				}

				a {
					color: ${theme.colors.primary};
					text-decoration: underline;
				}

				code {
					font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
				}

				#root {
					width: 100%;
					height: 100%;
				}
			`}
		/>
	</React.Fragment>
);
