import { lighten, transparentize } from 'polished';
import { ModularScaleRatio } from 'polished/lib/types/modularScaleRatio';

const primaryColor = 'rgb(40, 50, 160)';

const theme = {
	breakpoints: {
		// values copied from bootstrap
		phone: 320,
		tablet: 768,
		desktop: 992,
		large: 1200
	},
	colors: {
		text: 'rgb(0, 0, 0)',
		button: 'rgb(0, 202, 143)',
		link: 'rgb(0, 202, 143)',
		white: 'rgb(255, 255, 255)',
		black: 'rgb(0, 0, 0)',
		primary: primaryColor,
		sliderGradient: (degree: string = '90deg') =>
			`linear-gradient(${degree}, ${transparentize(0.95, primaryColor)} 0%, ${transparentize(0.7, primaryColor)} 100%)`,
		imageBackground: lighten(0.97, 'rgb(0, 0, 0)')
	},
	dimensions: {
		contentPadding: '20px',
		contentPaddingBig: '20px',
		maxCenteredContentWidth: '1280px',
		gridGap: '20px'
	},
	typography: {
		fontSizeScale: 'majorThird' as ModularScaleRatio, // 1.250, http://type-scale.com/
		body: {
			fontSize: '16px',
			fontFamily: ['sans-serif'],
			fontWeight: 400,
			boldFontWeight: 700,
			lineHeight: 1.5
		},
		heading: {
			fontFamily: ['inherit'],
			margin: '0.8em 0 0.512em',
			pageHeadingFontWeight: 500,
			fontWeight: 500,
			lineHeight: 1.2,
			color: 'rgb(0, 0, 0)'
		}
	},
	states: {
		active: {
			darken: 0.2,
			opacity: 0.7
		},
		disabled: {
			opacity: 0.5
		}
	}
};

export default theme;
