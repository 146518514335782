export const getCanvasFromFile = (file: File, maxLongestSide: number = 1200): Promise<HTMLCanvasElement> => {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.onload = () => resolve(getCanvasFromImage(image, maxLongestSide));
		image.onerror = message => reject(message);
		image.src = URL.createObjectURL(file);
		setTimeout(() => {
			URL.revokeObjectURL(image.src);
		}, 0);
	});
};

export const getCanvasFromImage = (element: HTMLImageElement, maxLongestSide: number = 600): HTMLCanvasElement => {
	const scaleFactorX = maxLongestSide / element.naturalWidth;
	const scaleFactorY = maxLongestSide / element.naturalHeight;
	const scale = Math.min(scaleFactorX, scaleFactorY, 1.0);
	const canvas = document.createElement('canvas');
	canvas.width = element.naturalWidth * scale;
	canvas.height = element.naturalHeight * scale;
	const context = canvas.getContext('2d')!;
	console.log(`source file content size: ${element.naturalWidth} / ${element.naturalHeight}`);
	context.drawImage(element, 0, 0, element.naturalWidth, element.naturalHeight, 0, 0, canvas.width, canvas.height);
	return canvas;
};
