import React from 'react';
import styled from '@emotion/styled';
import ReactSlider from 'react-slider';
import theme from '../designSystem/theme';

type Props = {
	readonly className?: string;
	readonly value: number;
	readonly orientation?: string;
	readonly onChange: (value: number) => void;
};

const Slider: React.FunctionComponent<Props> = props => {
	const { className, value, orientation, onChange } = props;
	return (
		<div className={className}>
			<StyledSlider
				value={value}
				onChange={onChange}
				orientation={orientation}
				renderTrack={(props: any, state: any) => (
					<StyledTrack orientation={orientation} {...props} index={state.index} />
				)}
				renderThumb={(props: any, state: any) => (
					<StyledThumb orientation={orientation} {...props}>
						{state.valueNow}
					</StyledThumb>
				)}
			/>
		</div>
	);
};

const StyledSlider = styled(ReactSlider)`
	width: ${(props: any) => (props.orientation === 'vertical' ? '40px' : '100%')};
	height: ${(props: any) => (props.orientation === 'vertical' ? '100%' : '40px')};
`;

const StyledThumb = styled.div`
	height: 40px;
	line-height: 40px;
	width: 40px;
	text-align: center;
	background-color: ${theme.colors.primary};
	color: #fff;
	border-radius: 50%;
	cursor: grab;

	&:focus {
		outline: none;
	}
`;

const StyledTrack = styled.div`
	top: 0;
	left: 0;
	width: ${(props: any) => (props.orientation === 'vertical' ? '100%' : undefined)};
	height: ${(props: any) => (props.orientation === 'vertical' ? undefined : '100%')};
	background: ${(props: any) =>
		props.index === 1
			? theme.colors.imageBackground
			: theme.colors.sliderGradient(props.orientation === 'vertical' ? '-180deg' : '90deg')};
	border-radius: 20px;
`;

export default Slider;
